/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-02 19:09:41
 * @Module: 消费记录
 */
 <template>
  <div style="background:#fff;overflow:hidden">
    <div style="padding:0 20px 20px">
      <hdqForm :showLabel="['expenseType','empUuid','date']" @search="search" ref="hdqForm" />
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="subject" label="消费功能" min-width="100" />
        <el-table-column label="时间">
          <template slot-scope="scope">{{ scope.row.transactAt|timeFormat('yy-mm-dd hh:MM:ss') }}</template>
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">{{ transactionTypeRender(scope.row.type) }}</template>
        </el-table-column>
        <el-table-column label="消费">
          <template slot-scope="scope">{{ scope.row.cost||'--' }}</template>
        </el-table-column>
        <el-table-column label="消费账号">
          <template slot-scope="scope">
            <i class="el-icon-ali-xingzhuang1" style="color:#0062FF"></i>
            <span>{{ scope.row.transactorName||'--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.note" placement="top" :disabled="!scope.row.note">
              <span @click="()=>open(scope)" class="el-icon-ali-xingzhuangjiehe1" :style="scope.row.note?'':'color:#999;'">
              </span>
            </el-tooltip>

          </template>
        </el-table-column>
      </el-table>
      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
    </div>
  </div>
</template>
 <script>

import hdqForm from "@/components/hdqForm.vue";
import hdqPagination from "@/components/hdqPagination.vue"
import selectData from "../../../mixins/selectData";
export default {
  mixins:[selectData],
  components: {
    hdqForm,
    hdqPagination
  },
  data () {
    return {
      list: []
    };
  },
  mounted () {
    this.getTransaction()
  },
  methods: {
    transactionTypeRender(value){
      return this.transactionType.find(res=>res.value == value).label
    },
    search () {
      this.$refs.pagination.createPagination({ current: 1 })
      this.getTransaction()
    },
    paginationChange () {
      this.getTransaction()
    },
    async getTransaction () {
      const { current, size } = this.$refs.pagination
      const { expenseType: type, ...getFormData } = this.$refs.hdqForm.getForm();

      const { status, data } = await this.$api.getTransaction({ current, size, ...getFormData, type })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>